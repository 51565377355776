<template>
  <Dialog v-model:visible="visible" :style="{width: '450px'}" header="Confirm" :modal="true">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="entities">{{ title }}</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="$emit('hideDialog')"/>
      <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="doDelete" />
    </template>
  </Dialog>
</template>

<script>

import StepService from "@/service/StepService";

export default {
  name: "MarketTestingBulkDelete",
  emits: ['hideDialog','deleted'],
  props: {
    entities: Array,
    dialogVisible: Boolean
  },
  entityService: null,

  computed: {
    visible: {
      get() { return this.dialogVisible },
      set(visible) {this.$emit('hideDialog', visible)}
    },
    title() {
      return 'Are you sure you want to delete the selected strategies?';
    }
  },

  created() {
    this.entityService = new StepService();
  },

  methods: {
    doDelete() {
      this.entityService.bulkDelete(this.entities).then(response => {
        this.$toast.add({severity:'success', summary: 'Successful', detail: 'Strategies deleted', life: 3000});
        this.$emit('deleted', response);
      });
    }
  }
}
</script>

<style scoped>

</style>
