<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
              <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedEntities || !selectedEntities.length" />
            </div>
          </template>

          <template v-slot:end>
            <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
            <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
          </template>
        </Toolbar>

        <DataTable ref="dt" :value="entities" v-model:selection="selectedEntities" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products" responsiveLayout="scroll">
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Manage strategies</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
            </div>
          </template>

          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>

          <Column v-for="(column, columnKey) of columns" :key="columnKey" :field="column.field" :header="column.header" :sortable="true" :headerStyle="column.headerStyle">
            <template #body="slotProps">
              <span class="p-column-title">{{ column.header }}</span>
              {{ getValue(slotProps.data, column.field) }}
            </template>
          </Column>

          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editEntity(slotProps.data)" />
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2" @click="confirmDeleteDialog(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
        <market-testing-form :dialog="editDialogVisible" :entity="entity" @saved="entitySaved" @hide-dialog="hideDialog"></market-testing-form>
        <market-testing-delete :dialog-visible="deleteDialogVisible" :entity="entity" @hide-dialog="hideDeleteDialog" @deleted="entityDeleted"></market-testing-delete>
        <market-testing-bulk-delete :dialog-visible="deleteBulkDialogVisible" :entities="selectedEntities" @@hide-dialog="hideBulkDeleteDialog" @deleted="entitiesDeleted"></market-testing-bulk-delete>
      </div>
    </div>
  </div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import MarketTestingService from "@/service/MarketTestingService";
import {tableMixin} from "@/mixin/tableMixin";
import MarketTestingForm from "@/components/price-calculator/ab-testing/form/MarketTestingForm";
import MarketTestingDelete from "@/components/price-calculator/ab-testing/form/MarketTestingDelete";
import MarketTestingBulkDelete from "@/components/price-calculator/ab-testing/form/MarketTestingBulkDelete";

export default {
  components: {MarketTestingBulkDelete, MarketTestingDelete, MarketTestingForm},
  mixins: [tableMixin],
  data() {
    return {
      entities: null,
      editDialogVisible: false,
      deleteDialogVisible: false,
      deleteBulkDialogVisible: false,
      entity: {},
      selectedEntities: null,
      filters: {},
      columns: [
        {
          field: 'title',
          header: 'Title',
          headerStyle: 'min-width:10rem;'
        },
        {
          field: 'type',
          header: 'Type',
          headerStyle: 'min-width:10rem;'
        },
        {
          field: 'description',
          header: 'Description',
          headerStyle: 'min-width:10rem;'
        },
        {
          field: 'strategy',
          header: 'Strategy',
          headerStyle: 'min-width:10rem;'
        },
        {
          field: 'factor',
          header: 'Factor',
          headerStyle: 'min-width:10rem;'
        },
        {
          field: 'value_type',
          header: 'Absolute/Relative',
          headerStyle: 'min-width:10rem;'
        },
      ],
    }
  },
  entityService: null,
  created() {
    this.entityService = new MarketTestingService();
    this.initFilters();
  },
  mounted() {
    this.loadEntities();
  },
  methods: {
    openNew() {
      this.resetEntity();
      this.editDialogVisible = true;
    },

    resetEntity() {
      this.entity = {};
    },

    loadEntities() {
      this.selectedEntities = null;
      this.entityService.fetchAll().then(data => this.entities = data.data);
    },

    entitySaved() {
      this.hideDialog();
      this.resetEntity();
      this.loadEntities();
    },
    hideDialog() {
      this.editDialogVisible = false;
    },

    entityDeleted() {
      this.hideDeleteDialog();
      this.loadEntities();
    },
    hideDeleteDialog() {
      this.deleteDialogVisible = false;
    },

    entitiesDeleted() {
      this.hideBulkDeleteDialog();
      this.loadEntities();
    },
    hideBulkDeleteDialog() {
      this.deleteBulkDialogVisible = false;
    },

    editEntity(entity) {
      this.entity = {...entity};
      this.editDialogVisible = true;
    },
    confirmDeleteDialog(entity) {
      this.entity = entity;
      this.deleteDialogVisible = true;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteBulkDialogVisible = true;
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },
    goToQuestions(step) {
      this.$router.push({
        name: 'step-questions',
        params: {
          stepId: step.id
        }
      });
    }
  }
}
</script>
